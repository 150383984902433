import { RemovableRef, useLocalStorage } from '@vueuse/core';
import { Commit } from 'vuex/types/index.d';

import { t } from '~i18n';
import { CustomerSalutation } from '~model';

interface State {
  form: {
    company: string | null;
    salutation: string | null;
    firstName: string | null;
    lastName: string | null;
    street: string | null;
    streetNumber: string | null;
    additionalAddress: string | null;
    postalCode: string | null;
    city: string | null;
    email: string | null;
    repeatEmail: string | null;
    email2: string | null;
    phone: string | null;
    donationReceipt: boolean | null;
    newShopCustomer: undefined | null | boolean;
    useDataConfirmation: boolean | null;
    useRecharge: boolean;
    paymentRequired: boolean;
    orderPrepaidVouchers: boolean | null;
    orderPrepaidVouchersConfirmation: boolean | null;
    redeemValue: number | null;
    campaign: string | null;
    prepaidPayAmount: RemovableRef<number> | number;
    prepaidEndAmount: RemovableRef<number> | number;
    promoRedeemValue?: number;
    promoValue?: number;
    isPaybackCustomer: RemovableRef<boolean | null> | boolean | null;
  };
}

declare global {
  interface Getters {
    getCustomerForm: State['form'];
    getUserEmail: State['form']['email'];
    getSalutations: CustomerSalutation[];
    getPrepaidPayAmount: number;
    getPrepaidEndAmount: number;
    getIsPaybackCustomer: boolean;
  }
}

export default {
  state: <State>{
    /*
     * The customer-form object containing all required or optional
     * attributes like firstName, lastName, email, etc.
     */
    form: {
      /* company: "MyCompany AG" */
      company: <string | null>null,

      /* salutation: "Mr." */
      salutation: <string | null>null,

      /* firstName: "John" */
      firstName: <string | null>null,

      /* lastName: "Doe" */
      lastName: <string | null>null,

      /* street: "Main Street" */
      street: <string | null>null,

      /* streetNumber: "105 b" */
      streetNumber: <string | null>null,

      /* additionalAddress: "1. Floor" */
      additionalAddress: <string | null>null,

      /* postal or zip code: "84573" */
      postalCode: <string | null>null,

      /* city: "Any City" */
      city: <string | null>null,

      /* email: "test@wunschgutschein.com" */
      email: <string | null>null,

      /* email2: "test@wunschgutschein.com" */
      email2: <string | null>null,

      /* repeatEmail: "test@wunschgutschein.com" */
      repeatEmail: <string | null>null,

      /* phone: "+491234567890" */
      phone: <string | null>null,

      /* check donation receipt for charity: null */
      donationReceipt: <boolean | null>null,

      /* newShopCustomer: -1 */
      newShopCustomer: <undefined | null | boolean>undefined,

      /* useDataConfirmation: null */
      useDataConfirmation: <boolean | null>null,

      /* if recharge is enabled: false */
      useRecharge: false,

      /* Check if a payment is Required: false */
      paymentRequired: false,

      /* orderPrepaidVouchers: true */
      orderPrepaidVouchers: <boolean | null>null,

      /* orderPrepaidVouchersConfirmation: true */
      orderPrepaidVouchersConfirmation: <boolean | null>null,

      /* value of the current selected redeem: 1000 */
      redeemValue: <number | null>null,

      /* campaign: null */
      campaign: <string | null>null,

      /* value of the amount to prepay the empty prepaid voucher: 1000 */
      prepaidEndAmount: useLocalStorage('prepaidEndAmount', <number | null>0),

      /* Check if user confirmed payback customer */
      isPaybackCustomer: useLocalStorage('isPaybackCustomer', null),
    },
  },

  mutations: {
    SET_PREPAID_END_AMOUNT(
      state: State,
      amount: number,
    ) {
      state.form.prepaidEndAmount = amount;
    },
  },

  actions: {
    setPrepaidEndAmount(
      { commit }: { commit: Commit },
      amount: number,
    ) {
      commit('SET_PREPAID_END_AMOUNT', amount);
    },
  },

  getters: <GettersDefinition<State>>{
    getCustomerForm: (state: State) => state.form,

    /*
     * Get the e-mail from customer (form)
     */
    getUserEmail: (state: State) => state.form.email,

    /*
     * Get salutations array
     */
    getSalutations: () => [
      {
        value: null,
        text: t('wgs.std.PleaseChoose'),
        disabled: true,
      },
      {
        value: t('wgs.std.Herr'),
        text: t('wgs.std.Herr'),
      },
      {
        value: t('wgs.std.Woman'),
        text: t('wgs.std.Woman'),
      },
      {
        value: '',
        text: t('wgs.std.notSpecified'),
      },
    ],

    getPrepaidPayAmount: (
      _state: State,
      getters: Getters,
    ): number => getters.getPrepaidEndAmount - getters.getVoucherValue,

    getPrepaidEndAmount: (
      state: State,
    ): number => Number(state.form.prepaidEndAmount),

    getIsPaybackCustomer: (state: State) => state.form.isPaybackCustomer,
  },

};
